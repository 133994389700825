<template>
  <div  class="modalClass" ref="container">
    <a-modal
        :visible="visible"
        :title="!form.id ? '新增' : '编辑'"
        :mask-closable="false"
        :width="600"
        centered
        @cancel="handleCancel"
        forceRender
        :destroyOnClose="true"
        :getContainer="() => container"
        @ok="handleOk"
    >
      <a-form
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          :model="form"
      >
        <a-form-item
            label="应用名称"
            v-bind="validateInfos.appName"
            class="flex-row"
        >
          <a-input v-model:value="form.appName" placeholder="请输入应用名称" />
        </a-form-item>

        <a-form-item
          label="描述"
          v-bind="validateInfos.desc"
          class="flex-row"
      >
        <a-input v-model:value="form.desc" placeholder="请输入描述" />
      </a-form-item>

        <a-form-item
            label="ota链接"
            v-bind="validateInfos.downloadUrl"
            class="flex-row"
        >
          <a-input v-model:value="form.downloadUrl" placeholder="请输入ota链接" />
        </a-form-item>

        <a-form-item
            label="文件大小"
            v-bind="validateInfos.fileSize"
            class="flex-row"
        >
          <a-input v-model:value="form.fileSize" placeholder="请输入文件大小" />
        </a-form-item>
        <a-form-item
            label="应用类型"
            v-bind="validateInfos.deviceType"
            class="flex-row"
        >
          <a-select
              v-model:value="form.deviceType"
              :options="deviceTypeOptions"
              placeholder="请选择"
          />
<!--          <a-input v-model:value="form.deviceType" placeholder="请输入应用类型" />-->
        </a-form-item>
        <a-form-item
            label="版本名称"
            v-bind="validateInfos.version"
            class="flex-row"
        >
          <a-input v-model:value="form.version" placeholder="版本名称" />
        </a-form-item>
        <a-form-item
            label="应用版本号"
            v-bind="validateInfos.versionCode"
            class="flex-row"
        >
          <a-input v-model:value="form.versionCode" placeholder="请输入应用版本号" />
        </a-form-item>

        <a-form-item label="应用渠道" v-bind="validateInfos.channelName">
          <a-input v-model:value="form.channelName" placeholder="请输入应用渠道" />
        </a-form-item>

        <a-form-item
            label="是否发布"
            v-bind="validateInfos.isRelease"
        >
          <a-select
              v-model:value="form.isRelease"
              :options="classifyOptions"
              placeholder="请选择"
          />
        </a-form-item>
        <a-form-item
            label="是否强制更新"
            v-bind="validateInfos.forcedUpdating"
        >
          <a-select
              v-model:value="form.forcedUpdating"
              :options="classifyOptions"
              placeholder="请选择"
          />
        </a-form-item>
      </a-form>

    </a-modal>
  </div>


</template>
<script>
import {reactive, toRefs, watch, ref, toRaw} from 'vue';
import {Form, message, Select} from "ant-design-vue";
import ordersApi from "@/api/orders.js";
// import { useStore } from 'vuex';


export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    // const store = useStore();
    const container = ref();
    const form = reactive({
      appName: '',
      desc: '',
      downloadUrl: '',
      fileSize: '',
      version: '',
      versionCode: '',
      channelName: '',
      isRelease: '',
      forcedUpdating: '',
      deviceType: '',
    });
    const required = { required: true, message: '不能为空' };
    const state = reactive({
      classifyOptions:[
        {
          value: false,
          label: '否',
        },
        {
          value: true,
          label: '是',
        },
      ],

      classifyOptionsT:[
        {
          value: false,
          label: '否',
        },
        {
          value: true,
          label: '是',
        },
      ],
      deviceTypeOptions:[
        {
          value: 0,
          label: '安卓',
        },
        {
          value: 1,
          label: 'IOS',
        },
        {
          value: 2,
          label: '鸿蒙',
        },
      ],

    });


    const handleCancel = () => {
      context.emit('update:visible', false);
    };
    watch(
        () => props.init,
        value => {
          console.log('value', value)
          Object.assign(form, value)
        },
        { deep: true }
    );

    const { resetFields, validate, validateInfos } = Form.useForm(form, {
      appName: [required],
      desc: [required],
      downloadUrl: [
          required,
        {
          type: 'url',
          message: '请输入正确的URL地址，以http或者https开头的链接',
        },
      ],
      fileSize: [required],
      deviceType: [required],
      version: [required],
      versionCode: [required],
      channelName: [required],
      isRelease: [required],
      forcedUpdating:[required]

    });

    return {
      validateInfos,
      ...toRefs(state),
      container,
      form,
      handleCancel,
      handleOpen() {
        state.openTiesGroup = true;
        // context.emit('fnOk', false);
      },
      handleOk() {
        validate().then(() => {
          let type = 'addVersion';
          form?.id && (type = 'updateVersion');
          // let data ={...toRaw(form)}
          ordersApi[type](`configuration:${type}`, { ...form }).then(()=>{
            message.success('操作成功');
            context.emit('fnOk', false);

          })
        });
      },

    };
  },
};
</script>
<style lang="less" scoped>
.modalClass /deep/ .ant-modal-body {
  height: 540px;
  overflow: auto;
  padding: 0px 24px;
  margin-top: 24px;
  font-size: 16px;

}

.content {
  margin-top: 20px;
}

.imgList {

}



</style>
